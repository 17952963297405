import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import moment from 'moment';
import Compressor from 'compressorjs'; // for compressing image size before upload
import 'firebase/compat/functions';

export const uploadImage = async (imageBase64Data: any, imageTitle: string, targetTable = 'feedback') => {
  // compress the image
  const res = await fetch(imageBase64Data);
  const blob = await res.blob();
  const imageFile: any = await new Promise((resolve, reject) => {
    new Compressor(blob, {
      maxWidth: 2000,
      quality: 0.6,
      success: resolve,
      error: reject,
    });
  });
  const extension = imageBase64Data.split(';')[0].split('/')[1];
  //const fileName = `${moment().format('YYYYMMDDHHmmss')}-${imageTitle.substring(0, 10)}.${extension}`; // WILL CAUSE COLLISION
  const fileName = `${new Date().valueOf()}-${imageTitle.substring(0, 20)}.${extension}`;
  
  // Fetch Cloudflare batch upload token
  const batchToken = (await firebase.app().functions('asia-east2').httpsCallable('wins-fetchImageUploadBatchToken')({ })).data;
  console.log(batchToken);

  // Upload to Cloudflare image storage via batch token
  const formData = new FormData();
  formData.append('file', imageFile, fileName);
  const response = await fetch('https://batch.imagedelivery.net/images/v1', {
    method: 'POST',
    headers: { 'Authorization': `Bearer ${batchToken}` },
    body: formData
  });

  if (!response.ok) throw new Error('Failed to upload image to Cloudflare');

  const result = await response.json();
  console.log(result);
  const { variants } = result.result;
  return variants.find(v => v.includes("/public")) || variants[0]; // URL of the uploaded image
  /*
  // upload the post image file to Firebase Storage
  const snapshot = await firebase.storage().ref(`${targetTable}-${fileName}`).put(imageFile);
  return await snapshot.ref.getDownloadURL();
  */
}