import { RouteRecordRaw } from 'vue-router';
import Tabs from '@/pages/Tabs.vue'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    component: () => import('@/pages/LoginPage.vue')
  },
  {
    path: '/register',
    component: () => import('@/pages/RegisterPage.vue')
  },
  {
    path: '/forgot-password',
    component: () => import('@/pages/ForgotPasswordPage.vue')
  },
  {
    path: '/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/home'
      },
      {
        path: 'home',
        component: () => import('@/pages/HomePage.vue')
      },
      {
        path: 'checkout',
        name: 'CheckoutPage',
        component: () => import('@/pages/booking/CheckoutPage.vue'),
        props: true,
      },
      {
        path: 'thank-you',
        name: 'ThankYouPage',
        component: () => import('@/pages/ThankYouPage.vue'),
        props: true,
      },
      {
        path: 'profile',
        component: () => import('@/pages/UserProfilePage.vue')
      },
      {
        path: 'terms-and-conditions',
        component: () => import('@/pages/TermsAndConditionsPage.vue')
      },
      {
        path: 'feedback/:projectId?/:locationId?',
        name: 'FeedbackPage',
        component: () => import('@/pages/FeedbackPage.vue')
      },
      {
        path: 'p',
        component: () => import('@/pages/project/ProjectListPage.vue'),
      },
      {
        path: 'p/:id',
        component: () => import("@/pages/project/ProjectDetailPage.vue"),
        props: true,
      },
      {
        path: 'projects',
        name: 'ProjectListPage',
        component: () => import('@/pages/project/ProjectListPage.vue'),
      },
      {
        path: 'projects/:id',
        name: 'ProjectDetailPage',
        component: () => import("@/pages/project/ProjectDetailPage.vue"),
        //props: true,
      },
      {
        path: 'ol/:projectId?/:workLocationType?/:workLocationId?/:workPhotoRecordId?',
        name: 'ProjectOLPage',
        component: () => import('@/pages/project/ProjectOLPage.vue'),
      },
      {
        path: 'notifications',
        name: 'NotificationListPage',
        component: () => import('@/pages/notification/NotificationListPage.vue'),
      },
      {
        path: 'notifications/:id',
        name: 'NotificationDetailPage',
        component: () => import("@/pages/notification/NotificationDetailPage.vue"),
        props: true,
      },
      {
        path: 'bookings',
        component: () => import('@/pages/booking/BookingPage.vue'),
      },
      {
        path: "bookings/:id",
        name: "BookingDetailPage",
        component: () => import("@/pages/booking/BookingDetailPage.vue"),
        props: true,
      },
    ]
  }
]