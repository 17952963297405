import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _withDirectives(_createVNode(_component_ion_tab_bar, { slot: "bottom" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_tab_button, {
                tab: "home",
                href: "/home"
              }, {
                default: _withCtx(() => [
                  ($setup.scanningQRCode)
                    ? (_openBlock(), _createBlock(_component_ion_icon, {
                        key: 0,
                        icon: $setup.scan
                      }, null, 8, ["icon"]))
                    : (_openBlock(), _createBlock(_component_ion_icon, {
                        key: 1,
                        icon: $setup.home
                      }, null, 8, ["icon"]))
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "projects",
                href: "/projects"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: $setup.construct }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.t('projects')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              ($setup.userLoggedIn)
                ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                    key: 0,
                    tab: "notifications",
                    href: "/notifications"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: $setup.notifications }, null, 8, ["icon"]),
                      _withDirectives(_createVNode(_component_ion_badge, {
                        id: "notifications-badge",
                        color: "danger"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.numOfNewNotifications), 1)
                        ]),
                        _: 1
                      }, 512), [
                        [_vShow, $setup.numOfNewNotifications > 0]
                      ]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('notifications')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              ($setup.userLoggedIn)
                ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                    key: 1,
                    tab: "bookings",
                    href: "/bookings"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: $setup.calendar }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('bookings')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_tab_button, {
                tab: "profile",
                href: "/profile"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: $setup.personCircle }, null, 8, ["icon"]),
                  ($setup.userLoggedIn)
                    ? (_openBlock(), _createBlock(_component_ion_label, { key: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('profile')), 1)
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_ion_label, { key: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('LoginPage.login')), 1)
                        ]),
                        _: 1
                      }))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512), [
            [_vShow, !$setup.hideTabs]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}