
// Vue reactivity
import { onBeforeUnmount, onMounted, reactive, ref } from 'vue';

// icons
import { logIn, qrCodeOutline, scan, close, mail, key, globe, arrowForward, } from 'ionicons/icons';

// components
import { IonCol, IonRow, IonButtons, IonButton, IonIcon,
        IonCard, IonCardHeader, IonCardContent, IonItem, IonBackButton,
        IonLabel, IonInput, IonSelect, IonSelectOption, IonItemDivider,
        loadingController, modalController, alertController, } from "@ionic/vue";
import LogoImg from '@/components/LogoImg.vue';
import CompleteProfileModal from '@/components/auth/CompleteProfileModal.vue';

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';

// API services & Firebase
import AuthService from '@/services/AuthService';
import firebase from 'firebase/compat/app';
import { auth, credentialSignIn, phoneSignIn, } from '@/auth';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

export default {
  props: ["isFromModal"],
  components: {
    IonCol, IonRow, IonButtons, IonButton, IonIcon,
    IonCard, IonCardHeader, IonCardContent, IonItem, IonBackButton,
    IonLabel, IonInput, IonSelect, IonSelectOption, IonItemDivider, LogoImg,
  },
  setup() {
    const store = useStore();
    const { t, locale } = useI18n();
    const { presentToast, presentAlert, iPhoneNativeApp, openModal, } = utils();

    const countryCodeOptions = require('@/assets/country_codes.json');
    const user = reactive({
      dialCode: "+852",
      phone: "",
    });
    const window: any = {
      recaptchaVerifier: undefined,
      confirmationResult: undefined,
      verificationId: undefined,
    };

    const verifyCode = async (verificationCode: any, alert: any = null, phoneExists: boolean) => {
      const loading = await loadingController.create({});
      await loading.present();
      try {
        let res: any = {};
        if (iPhoneNativeApp()) {
          const credential = firebase.auth.PhoneAuthProvider.credential(window.verificationId, verificationCode);
          res = await credentialSignIn(credential);
        } else {
          res = await window.confirmationResult.confirm(verificationCode);
        }
        loading.dismiss();
        if (alert) alert.dismiss();

        if (!phoneExists) {
          store.dispatch('createNewUser', { uid: res.user?.uid, ...user, });
          openModal(CompleteProfileModal, {}, false, 'login-modal');
        } else {
          modalController.dismiss();
        }
      } catch (e) {
        loading.dismiss();
        if (e.code == "auth/invalid-verification-code") { // wrong verification code
          presentAlert(t('RegisterPage.invalidVerificationCode'))
        } else {
          presentAlert(e.message);
        }
      }
    }
    const presentCodeInputAlert = async (phone: any, phoneExists: boolean) => {
      loadingController.dismiss();
      const alert = await alertController.create({
        backdropDismiss: false,
        header: t('RegisterPage.verifyingMobileNumber'),
        subHeader: `${t('RegisterPage.verifyMobileNumberInstruction')} (${phone}).`,
        inputs: [
          {
            name: 'verificationCode',
            type: 'text',
            placeholder: t('RegisterPage.verificationCode'),
            attributes: {
              inputmode: 'numeric',
              maxlength: '6',
            }
          },
        ],
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: t('RegisterPage.verify'),
            handler: (value) => {
              if (value.verificationCode) {
                verifyCode(value.verificationCode, alert, phoneExists);
              }
              return false; // not closing the alert
            },
          },
        ],
      });
      await alert.present();
    }
    const signInWithPhoneNumber = async (verifyingPhone) => {
      return new Promise(async (resolve) => {
        // Attach `phoneCodeSent` listener to be notified as soon as the SMS is sent
        await FirebaseAuthentication.addListener('phoneCodeSent', async (event) => {
          resolve(event.verificationId);
        });
        // Start sign in with phone number and send the SMS
        await FirebaseAuthentication.signInWithPhoneNumber({ phoneNumber: verifyingPhone });
      });
    };
    const doLogin = async () => {
      const { phone, dialCode } = user;
      if (dialCode && phone) {
        const verifyingPhone = `${dialCode}${phone}`; // e.g. +85261234567

        const loading = await loadingController.create({});
        await loading.present();
        const phoneExists = await AuthService.checkPhoneExists(verifyingPhone);
        try {
          // verify the phone
          const appVerifier = window.recaptchaVerifier;
          if (iPhoneNativeApp()) {
            const verificationId = await signInWithPhoneNumber(verifyingPhone);
            //const { verificationId } = await FirebaseAuthentication.signInWithPhoneNumber({ phoneNumber: verifyingPhone });
            loading.dismiss();
            window.verificationId = verificationId;
            presentCodeInputAlert(verifyingPhone, phoneExists);
          } else {
            window.confirmationResult = await phoneSignIn(verifyingPhone, appVerifier);
            presentCodeInputAlert(verifyingPhone, phoneExists);
          }
        } catch (e) {
          loadingController.dismiss();
          if (e.code == "auth/invalid-phone-number") {
            presentAlert(t('RegisterPage.enterValidHKMobileNumber')); // invalid phone number
          } else {
            presentAlert(e.message);
          }
        }
      } else {
        presentAlert(t('LoginPage.enterPhone'));
      }
    }

    onMounted(() => {
      document.querySelector('#recaptcha-container').innerHTML = '<div id="grecaptcha-div"></div>';
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('grecaptcha-div', {
        'size': 'invisible',
        'callback': (res: any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        }
      });
    });
    onBeforeUnmount(() => {
      window.recaptchaVerifier.clear();
    });

    return {
      // icons
      logIn, qrCodeOutline, scan, close, mail, key, globe,
      arrowForward,
      
      // variables
      locale,
      user,
      countryCodeOptions,

      // methods
      t, doLogin,
    }
  }
};
