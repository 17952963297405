export default {
  versionCode: '5.3.2',
  websiteUrl: 'https://www.baily.hk/',
  
  primaryColor: '#fda619',
  logo: require('@/assets/logo.png'),
  logoWithText: require('@/assets/logo.png'),

  googleMapsApiKey: "AIzaSyBAfbeaXmrlCnW4dICmbieqlX-771Wz_G0",

  Firebase: {
    apiKey: "AIzaSyCckp1JH79bf46NNVOGq3JVv9ZhA68aQ2U",
    authDomain: "bailyhk.firebaseapp.com",
    projectId: "bailyhk",
    storageBucket: "bailyhk.appspot.com",
    messagingSenderId: "200634013505",
    appId: "1:200634013505:web:178edbfecd9c09581533c0",
    measurementId: "G-54XYGT9J87"
  },

  Stripe: {
    //publishableKey: 'pk_test_51HVue8F5eqkgmghRoS3cNn8SMdn9ou9PIffiirbX1a4BvdgPSgCGHWvX6EO4XQF6ppPZdkFxyJHE8MmwGs2aby8P00g5qBbCG0', // test
    publishableKey: 'pk_live_51J90ZlHNcjEk5ciiqCLIxVjJigamzZ7gtKjlUEzfaEVaodkLAnlGJFwnKvL8APyH2iO64MJZYMiJE7whyqI1V6RY00gGqdyGRv',
  },

  OneSignal: {
    appId: 'cb736d94-d700-4216-9112-0decdc56d6e8',
  },

  hideBottomTabsPageNames: ["ProjectOLPage", "ProjectDetailPage"],
  authPages: ["/forgot-password", "/login", "/register"],
  memberPages: ["/checkout", "/profile", "/bookings", "/notifications", "/ol"],
  memberPageNames: ["ProjectOLPage"],
}