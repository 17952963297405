import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { uploadImage } from '@/services/utils';

export default {
  async updateUserLastAccessProjectTime(projectId) {
    return (await firebase.app().functions('asia-east2').httpsCallable('user-updateUserLastAccessProjectTime')({ projectId })).data;
  },

  /**
   * Asset
   */
  async createNewAsset(payload: any = {}) {
    return (await firebase.app().functions('asia-east2').httpsCallable('project-createNewAsset')(payload)).data;
  },
  async updateAsset(payload: any = {}) {
    return (await firebase.app().functions('asia-east2').httpsCallable('project-updateAsset')(payload)).data;
  },
  async linkChildAssetToDevice(childAssetId, deviceId, parentId, projectId, oldDeviceId = null) {
    return (await firebase.app().functions('asia-east2').httpsCallable('project-linkChildAssetToDevice')({ childAssetId, deviceId, parentId, projectId, oldDeviceId, })).data;
  },
  async updateAssetPointXY(id, pointX, pointY) {
    return (await firebase.app().functions('asia-east2').httpsCallable('project-updateAsset')({ id, pointX, pointY })).data;
  },
  async deleteAssetPoint(id) {
    return (await firebase.app().functions('asia-east2').httpsCallable('project-updateAsset')({ id, projectId: '' })).data;
  },

  /**
   * Device
   */
  async upsertDevice(payload: any = {}) {
    return (await firebase.app().functions('asia-east2').httpsCallable('project-upsertDevice')(payload)).data;
  },
  async updateDevicePointXY(id, pointX, pointY) {
    return (await firebase.app().functions('asia-east2').httpsCallable('project-upsertDevice')({ id, pointX, pointY })).data;
  },
  async deleteDevicePoint(id) {
    return (await firebase.app().functions('asia-east2').httpsCallable('project-upsertDevice')({ id, projectId: '' })).data;
  },

  /**
   * BD report (work photo records)
   */
  async updateCheckLocationImage(projectId, id, step, photo = null, photoLink = null) {
    const newPhotoLink = photoLink || (photo ? (await uploadImage(photo.base64Data, `${id}-${step}`, 'ol')) : "");
    await firebase.app().functions('asia-east2').httpsCallable('project-upsertCheckLocation')({ id, step, newPhotoLink, projectId });
    return newPhotoLink;
  },
  async updateCheckLocationPointXY(id, pointX, pointY) {
    return (await firebase.app().functions('asia-east2').httpsCallable('project-upsertCheckLocation')({ id, pointX, pointY })).data;
  },
  async deleteCheckLocation(id) {
    return (await firebase.app().functions('asia-east2').httpsCallable('project-upsertCheckLocation')({ id, status: 'trashed' })).data;
  },
  async upsertCheckLocation({ projectId, id = null, pointX, pointY, workLocationId, targetFloor, photoCode, damageType, damageAreaLength, damageAreaWidth, ironState, subLocationId, }) {
    const payload = { projectId, id, pointX, pointY, workLocationId, targetFloor, photoCode, damageType, damageAreaLength, damageAreaWidth, ironState, subLocationId, };
    return (await firebase.app().functions('asia-east2').httpsCallable('project-upsertCheckLocation')(payload)).data;
  },

  // READ
  async getAllProjects() {
    return (await firebase.app().functions('asia-east2').httpsCallable('project-getAllProjects')({})).data;
  },
  async getProjectById(projectId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('project-getProjectById')({ projectId })).data;
  },

  // UPDATE
  async subscribeProjectNotifications(projectId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('project-subscribeProjectNotifications')({ projectId });
  },
  async unsubscribeProjectNotifications(projectId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('project-unsubscribeProjectNotifications')({ projectId });
  }
}